<template>
  <div class="page">
    <Navbar isBack="true" :title="'个人信息'"/>
    <div class="page_box">
      <div class="page_box_contant">
        <div class="cell_title">个人资料</div>
        <div class="cell">
          <div class="cell_name">用户ID：</div>
          <div class="cell_val">M12345255</div>
        </div>
        <div class="cell">
          <div class="cell_name">账号：</div>
          <div class="cell_val">40225252@qq.com</div>
        </div>
        <div class="cell">
          <div class="cell_name">邮箱：</div>
          <div class="cell_val">40225252@qq.com</div>
        </div>
        <div class="cell">
          <div class="cell_name">手机号：</div>
          <div class="cell_val">1425035355</div>
        </div>
        <div class="cell_title">账户信息</div>
        <div class="cell">
          <div class="cell_name">账户积分：</div>
          <div class="cell_val">53</div>
        </div>
        <div class="cell">
          <div class="cell_name">账户等级：</div>
          <div class="cell_val">2</div>
        </div>
        <div class="cell">
          <div class="cell_name">等级经验：</div>
          <div class="cell_val">402</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data(){
    return {

    }
  },
  mounted () {
  },
  methods: {
   
  }
}
</script>
<style scoped lang='scss'>
.page{
  background-color: #f0f0f0;
}
.header{
  width: 100%;
  background-color: #fff;
}
.page_box{
  padding: .2rem;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}
.page_box_contant{
  padding: 0 .2rem 1rem .2rem;
  border-radius: .2rem;
  box-shadow: 0 0 5px #ddd;
  background-color: #fff;
  font-size: .26rem;
  width: 85%;
}
.cell_title{
  padding: .5rem 0 .1rem 0;
  color: $color-primary;
}
.cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  border-bottom: 1px solid #eee;
}

</style>